import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import RecentPosts from "../components/recent_posts"
import SEO from "../components/seo"
import { Link } from "gatsby"

const IndexPage = () => (
  <Layout>
    <SEO title="Home"
      description="Sydney based full-stack developer, who has worked in PHP and Drupal applications, implementing front end changes, and DevOps." />
    <p>I’ve been developing websites for the last 3 years. Through the course of my career, I’ve been responsible for:</p>
    <ul>
      <li>Back End Development—primarily in PHP and Drupal applications, to advise and build functionality to meet business objectives.</li>
      <li>Front End Development—maintenance and implementation of design changes, as well as building accessible components.</li>
      <li>DevOps—setting up software stacks and applying security best practice in configuration, applying and deploying security updates to applications</li>
    </ul>
    <p>This site was built with <a href="https://www.gatsbyjs.org/">Gatsby</a>. A post about it can be found <Link to="/blog/my-gatsby-setup/">here</Link>.</p>
    <RecentPosts/>
  </Layout>
)

export default IndexPage
