import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import DateTime from "../components/datetime"
// import styles from "./post-list.module.scss"
// import stylesBtn from "./buttons.module.scss"

const RecentPosts = () => {
  const data = useStaticQuery(graphql`
    query RecentPosts {
      allMarkdownRemark(limit: 3, sort: { fields: [frontmatter___date], order: DESC }, filter: {published: {eq: true}, fileAbsolutePath: {regex: "/blog/"}}) {
        edges {
          node {
            id
            excerpt
            html
            frontmatter {
              title
              htmldate: date
              date(formatString: "D MMM YYYY")
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `)

  // let button_style = [
  //   `${stylesBtn.button}`,
  //   `${styles.viewAll}`
  // ]

  // button_style = button_style.join(' ')

  return (
    <>
      <h1>Recent posts</h1>
      <ul className="list-none m-0">
        {data.allMarkdownRemark.edges.map(({ node }) => (
          <li className="{styles.item} pl-0 mb-4 sm:mb-2" key={node.id}>
            <article>
              <Link to={node.fields.slug} className="{styles.link} block sm:flex sm:m-0 sm:justify-between sm:align-center">
                <h2 className="{styles.title} text-xl sm:text-l">{node.frontmatter.title}</h2>
                <DateTime datetime={node.frontmatter.htmldate} dateformatted={node.frontmatter.date} />
              </Link>
            </article>
          </li>
        ))}
      </ul>
      <p style={{
        marginTop: `2rem`
      }}><Link to="/blog/" className="{button_style} rounded-md font-sans inline-block py-2 px-4 pr-6 bg-blue-900 text-white" style={{paddingRight: `1.6rem`}}>View the full list of posts</Link></p>
    </>
  )
}

export default RecentPosts
